<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.agentOffice.list')"
    class="px-5 py-3"
  >
    <base-add-button :permissions="permissions" :basePath="basePath" :title="$t('agentOffice.add')">
    </base-add-button>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="text-center primary--text">
            {{ $t('general.actions') }}
          </th>
          <th class="text-center primary--text">
            {{ $t('general.openDetail') }}
          </th>
          <th class="primary--text">ID</th>
          <th class="primary--text">
            {{ $t('agentOffice.agency') }}
          </th>
          <th class="primary--text">
            {{ $t('agentOffice.name') }}
          </th>
          <th class="primary--text">
            {{ $t('agentOffice.nib') }}
          </th>
          <th class="primary--text">
            {{ $t('agentOffice.logo') }}
          </th>
          <th class="primary--text">
            {{ $t('agentOffice.updatedAt') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>
            <div class="d-flex justify-center align-center">
              <base-edit-button
                :permissions="permissions"
                :basePath="basePath"
                :id="item.id"
              ></base-edit-button>
              <base-delete-button
                :permissions="permissions"
                :id="item.id"
                :showIcon="true"
                @deleteAction="deleteAction"
              ></base-delete-button>
            </div>
          </td>
          <td>
            <base-detail-button
              :permissions="permissions"
              :basePath="basePath"
              :id="item.id"
              style="text-align: center !important"
            ></base-detail-button>
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.agency }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.nib }}</td>
          <td>
            <a :href="item.logo_url" target="_blank" v-if="item.logo_url"
              ><img
                :src="item.logo_url"
                style="max-width: 100px; padding: 4px; aspect-ratio: 1; object-fit: contain"
            /></a>
          </td>
          <td style="min-width: 200px">
            {{ item.updated_at ? format(new Date(item.updated_at), 'dd MMMM yyyy HH:mm') : '' }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
const Pagination = () => import('@/components/utils/fractal-pagination');
import { format } from 'date-fns';

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.agentOffice.basePath,
      websiteUrl: (state) => state.global.websiteUrl,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
    async deleteAction(id) {
      console.log('DELETE AGENT OFFICE UUID: ', id);
      try {
        await this.$store.dispatch('agentOffice/delete', id);
        this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('agentOffice.delete.successMsg'),
          'success',
        );
        await this.$store.dispatch('agentOffice/getInitData', this.$route.query);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
